import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import useLoadingWithDelay from '../hooks/useLoadingWithDelay';
import logoImage from '../images/logo.png';
import { auth, db } from '../firebase'; // Ajout de Firestore ici
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'; // Importation de Firestore
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import { useLoading } from '../contexts/LoadingContext';

const Login = () => {
  const navigate = useNavigate();
  const { updateCurrentUser, currentUser } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { setIsLoading } = useLoading();  // Utiliser le contexte pour gérer le chargement

  const handleLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Vérification de l'existence du document dans Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // Si l'utilisateur n'a pas de document dans Firestore, déconnexion
        await signOut(auth);
        throw new Error('Compte introuvable dans la base de données.');
      }

      // Si le document existe, mettre à jour le statut utilisateur
      await updateCurrentUser(user); 
      setIsLoading(false);  // Masque le préchargeur après la connexion réussie
      navigate('/');  // Redirige vers la page d'accueil

    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setError(error.message);
      setIsLoading(false);  // Masque le préchargeur en cas d'erreur
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);  // Montre le préchargeur au début
    await handleLogin(email, password);  // Appel de la fonction handleLogin pour gérer la connexion et la vérification
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-96">
        <CardHeader className="text-center">
          <img src={logoImage} alt="Logo" className="mx-auto mb-4 w-54 h-24" />
          <CardTitle className="text-2xl font-bold">Connexion</CardTitle>
        </CardHeader>
        <CardContent>
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="flex justify-center">
              <Button type="submit" className="w-full transition-colors hover:bg-blue-600">Se connecter</Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
