import React, { useState, useRef, useEffect } from 'react';
import { setDoc, updateDoc, deleteDoc, doc, collection, getDocs } from 'firebase/firestore';
import { auth, db, storage } from '../firebase'; // Assure-toi que Firestore, Auth et Storage sont bien importés
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { createUserWithEmailAndPassword, updatePassword, deleteUser } from 'firebase/auth'; // Import de Firebase Auth
import { Upload, Edit, Trash2, Save, X, Eye, EyeOff } from 'lucide-react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage


const UserManagement = () => {
  const [users, setUsers] = useState([]); // Liste des utilisateurs
  const [newUser, setNewUser] = useState({
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    profilePicture: null,
    role: 'collaborateur',
    poste: 'Développeur', // Poste remplacé ici
  });

  const [editingUser, setEditingUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [message, setMessage] = useState(''); // Message de succès/erreur
  const [isFormVisible, setIsFormVisible] = useState(false); // Masquer/afficher le formulaire d'ajout
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'home'); // Onglet actif
  const fileInputRef = useRef(null);

  // Fonction pour récupérer les utilisateurs depuis Firestore
  const fetchUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  useEffect(() => {
    fetchUsers(); // Récupère les utilisateurs lors du chargement du composant
  }, []);

  // Persister l'onglet actif dans le localStorage
  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({ ...newUser, profilePicture: file }); // Stocker le fichier pour l'upload dans Firebase Storage
    }
  };

  // Fonction pour ajouter un utilisateur
  const handleAddUser = async () => {
    setIsLoading(true); // Affiche le loader
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const user = userCredential.user;
  
      let profilePictureUrl = null;
      if (newUser.profilePicture) {
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        const uploadResult = await uploadBytes(storageRef, newUser.profilePicture);
        profilePictureUrl = await getDownloadURL(uploadResult.ref);
      }
  
      // Utiliser `setDoc` avec l'UID comme identifiant du document Firestore
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,  // Assure-toi que l'UID est utilisé comme identifiant
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        phone: newUser.phone,
        role: newUser.role,
        profilePicture: profilePictureUrl,
        poste: newUser.poste,  // Ajout du poste
      });
  
      fetchUsers(); // Actualise la liste des utilisateurs après ajout
      setMessage('Utilisateur ajouté avec succès !');
      setIsFormVisible(false); // Masque le formulaire après succès
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
      setMessage('Erreur lors de l\'ajout de l\'utilisateur.');
    } finally {
      setIsLoading(false); // Cache le loader
    }
  };
  

  // Fonction pour modifier un utilisateur
  const handleUpdateUser = async () => {
    if (!editingUser) return;

    try {
      const userRef = doc(db, 'users', editingUser.id);
      await updateDoc(userRef, {
        ...editingUser,
      });

      if (editingUser.password) {
        const authUser = auth.currentUser;
        await updatePassword(authUser, editingUser.password); // Met à jour le mot de passe dans Firebase Auth
      }

      fetchUsers(); // Actualise la liste des utilisateurs après modification
      setMessage('Utilisateur mis à jour avec succès');
      setEditingUser(null);
      setSelectedUser(editingUser);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
    }
  };

  // Fonction pour supprimer un utilisateur
  const handleDeleteUser = async (userId) => {
    try {
      // Suppression de l'utilisateur dans Firestore
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);
  
      // Récupérer l'utilisateur actuel dans Firebase Auth
      const user = auth.currentUser; 
      if (user.uid === userId) {
        await deleteUser(user); // Suppression de l'utilisateur dans Firebase Authentication
      }
  
      fetchUsers(); // Actualiser la liste des utilisateurs
      setMessage('Utilisateur supprimé avec succès');
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur :', error);
    }
  };

  // Pour sélectionner un utilisateur
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setEditingUser(null);
  };

  // Masquer/Afficher le formulaire
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  return (
    <div className="space-y-6">
      {/* Bouton pour afficher le formulaire d'ajout */}
      <Button onClick={toggleFormVisibility} className="mb-4">
        {isFormVisible ? 'Masquer le formulaire' : 'Ajouter un nouvel utilisateur'}
      </Button>

      {/* Formulaire d'ajout d'utilisateur */}
      {isFormVisible && (
        <Card>
          <CardHeader>
            <CardTitle>Ajouter un nouvel utilisateur</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              <Input name="password" type={showPassword ? "text" : "password"} placeholder="Mot de passe" value={newUser.password} onChange={handleInputChange} />
              <Input name="firstName" placeholder="Prénom" value={newUser.firstName} onChange={handleInputChange} />
              <Input name="lastName" placeholder="Nom" value={newUser.lastName} onChange={handleInputChange} />
              <Input name="email" type="email" placeholder="Email" value={newUser.email} onChange={handleInputChange} />
              <Input name="phone" placeholder="Téléphone" value={newUser.phone} onChange={handleInputChange} />

              <select name="role" value={newUser.role} onChange={handleInputChange} className="border p-2 rounded">
                <option value="">Sélectionnez un rôle</option>
                <option value="admin">Administrateur</option>
                <option value="collaborateur">Collaborateur</option>
              </select>

              <select name="poste" value={newUser.poste} onChange={handleInputChange} className="border p-2 rounded">
                <option value="Développeur">Développeur</option>
                <option value="Annotateur">Annotateur</option>
                <option value="Direction">Direction</option>
                <option value="Agent Call">Agent Call</option>
                <option value="Autres">Autres</option>
              </select>

              <div className="col-span-2">
                <input type="file" ref={fileInputRef} onChange={handleFileChange} className="hidden" accept="image/*" />
                <Button onClick={() => fileInputRef.current.click()} className="flex items-center">
                  <Upload className="mr-2 h-4 w-4" /> Télécharger une photo de profil
                </Button>
                {newUser.profilePicture && <img src={URL.createObjectURL(newUser.profilePicture)} alt="Preview" className="mt-2 w-32 h-32 object-cover rounded-full" />}
              </div>
            </div>

            <br />
            {isLoading ? (
              <p>Chargement...</p> // Loader pendant l'ajout
            ) : (
              <div className="flex justify-center">
                <Button onClick={handleAddUser} className="transition-colors hover:bg-blue-500 hover:text-white">
                  Ajouter l'utilisateur
                </Button>
              </div>
            )}
            {message && <p className="text-green-500 mt-2">{message}</p>}
          </CardContent>
        </Card>
      )}

      {/* Liste des utilisateurs */}
      <div className="grid grid-cols-3 gap-4">
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Liste des utilisateurs</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {users.map((user) => (
                <li
                  key={user.id}
                  className={`cursor-pointer p-2 rounded ${selectedUser?.id === user.id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                  onClick={() => handleUserSelect(user)}
                >
                  <div className="flex items-center space-x-2">
                    {user.profilePicture && <img src={user.profilePicture} alt={`${user.firstName} ${user.lastName}`} className="w-8 h-8 rounded-full object-cover" />}
                    <span>{user.firstName} {user.lastName}</span>
                  </div>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>

        {/* Détails de l'utilisateur */}
        {selectedUser && (
          <Card className="col-span-2">
            <CardHeader>
              <CardTitle>Profil utilisateur</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex space-x-4">
                <img src={selectedUser.profilePicture || '/api/placeholder/200/200'} alt={`${selectedUser.firstName} ${selectedUser.lastName}`} className="w-48 h-48 rounded-full object-cover" />
                <div>
                  <h2 className="text-2xl font-bold">{selectedUser.firstName} {selectedUser.lastName}</h2>
                  <p className="text-blue-500">{selectedUser.email} - {selectedUser.role === 'admin' ? 'Administrateur' : 'Collaborateur'}</p>
                  <p>Poste : {selectedUser.poste}</p>
                  <p>Téléphone : {selectedUser.phone}</p>

                  <div className="flex space-x-2 mt-4">
                    <Button onClick={() => setEditingUser(selectedUser)} className="transition-all hover:bg-blue-500 hover:text-white">
                      <Edit className="mr-2 h-4 w-4" /> Modifier
                    </Button>
                    <Button onClick={() => handleDeleteUser(selectedUser.id)} variant="destructive" className="transition-all hover:bg-red-600 hover:text-white">
                      <Trash2 className="mr-2 h-4 w-4" /> Supprimer
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Modification de l'utilisateur */}
        {editingUser && (
          <Card className="col-span-2">
            <CardHeader>
              <CardTitle>Modifier l'utilisateur</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Input name="poste" value={editingUser.poste} onChange={(e) => setEditingUser({ ...editingUser, poste: e.target.value })} placeholder="Poste" />
                <Input name="password" type={showPassword ? "text" : "password"} value={editingUser.password} onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })} placeholder="Mot de passe" />
                <Input name="firstName" value={editingUser.firstName} onChange={(e) => setEditingUser({ ...editingUser, firstName: e.target.value })} placeholder="Prénom" />
                <Input name="lastName" value={editingUser.lastName} onChange={(e) => setEditingUser({ ...editingUser, lastName: e.target.value })} placeholder="Nom" />
                <Input name="email" value={editingUser.email} onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })} placeholder="Email" />
                <Input name="phone" value={editingUser.phone} onChange={(e) => setEditingUser({ ...editingUser, phone: e.target.value })} placeholder="Téléphone" />
                
                <Button onClick={handleUpdateUser} className="transition-all hover:bg-blue-500 hover:text-white">
                  <Save className="mr-2 h-4 w-4" /> Mettre à jour
                </Button>
                <Button onClick={() => setEditingUser(null)} variant="outline">
                  <X className="mr-2 h-4 w-4" /> Annuler
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
