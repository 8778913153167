import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

export const updateMessageStatus = async (messageId, status) => {
  try {
    await updateDoc(doc(db, 'messages', messageId), { status });
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut du message:", error);
  }
};

export const markMessageAsRead = async (messageId) => {
  await updateMessageStatus(messageId, 'lu');
};

export const markMessageAsSent = async (messageId) => {
  await updateMessageStatus(messageId, 'envoyé');
};