import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCAj2NcjASlbjB4swGY5ljfdrAAoIdTh4Q",
    authDomain: "tdi-gestionprj.firebaseapp.com",
    projectId: "tdi-gestionprj",
    storageBucket: "tdi-gestionprj.appspot.com",
    messagingSenderId: "1088262421687",
    appId: "1:1088262421687:web:1f7e8341eb8fa6e4e0a132",
    measurementId: "G-S7ZPH78B8E"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);  // Use Firestore instead of Realtime Database
const auth = getAuth(app);
const storage = getStorage(app);


export { auth, db, storage };
