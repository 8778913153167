import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Search, Home, MessageSquare, CheckSquare, LogOut, Users, Activity, Camera } from 'lucide-react';
import { Card, CardContent } from './components/ui/card';
import { Input } from './components/ui/input';
import { Button } from './components/ui/button';
import Login from './components/Login';
import UserManagement from './components/UserManagement';
import Preloader from './components/Preloader';
import { LoadingProvider, useLoading } from './contexts/LoadingContext';
import MessagesTab from './components/MessagesTab';
import TasksTab from './components/TasksTab';
import QuickLinks from './components/QuickLinks';
import { auth, db } from './firebase';
import { signOut } from 'firebase/auth';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { UserProvider, useUserContext } from './contexts/UserContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminTab from './components/AdminTab';
import FlowTab from './components/FlowTab';
import { requestNotificationPermission } from './utils/notificationService';
import { NotificationProvider } from './contexts/NotificationContext';
import VisioTab from './VisioTab';

const AppContent = () => {
  console.log("Rendu de AppContent");
  const { currentUser, users } = useUserContext();
  const [activeTab, setActiveTab] = useState('home');
  const { isLoading, setIsLoading } = useLoading();
  const [messageTabUnreadCount, setMessageTabUnreadCount] = useState(0);
  const [quickLinks, setQuickLinks] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const { updateCurrentUser } = useUserContext();
  const [totalNotifications, setTotalNotifications] = useState(0);
  const renderFlowTab = useMemo(() => (
    <FlowTab />
  ), []);

  const renderVisioTab = useMemo(() => (
    <VisioTab />
  ), []);

  const handleNotificationChange = useCallback((count) => {
    setTotalNotifications(count);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser || !currentUser.id) return;

      setIsLoading(true);
      try {
        const quickLinksQuery = query(collection(db, 'quicklinks'), where('userId', '==', currentUser.id));
        const unsubscribeQuickLinks = onSnapshot(quickLinksQuery, (snapshot) => {
          setQuickLinks(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        const tasksQuery = query(collection(db, 'tasks'), where('userId', '==', currentUser.id));
        const unsubscribeTasks = onSnapshot(tasksQuery, (snapshot) => {
          setTasks(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });

        return () => {
          unsubscribeQuickLinks();
          unsubscribeTasks();
        };
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser, setIsLoading]);

  

  const addQuickLink = useCallback(async (newLink) => {
    if (!currentUser || !currentUser.id) return;
    try {
      await addDoc(collection(db, 'quicklinks'), {
        ...newLink,
        userId: currentUser.id,
        createdAt: new Date()
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout du lien rapide:", error);
    }
  }, [currentUser]);

  const deleteQuickLink = useCallback(async (linkId) => {
    try {
      await deleteDoc(doc(db, 'quicklinks', linkId));
    } catch (error) {
      console.error("Erreur lors de la suppression du lien rapide:", error);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        await setDoc(doc(db, 'status', userId), { online: false }, { merge: true });
      }
      await signOut(auth);
      localStorage.removeItem('token');
      setActiveTab('home');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  }, []);

  const renderUsersList = useMemo(() => {
    if (!users || users.length === 0) {
      return <p>Aucun utilisateur trouvé.</p>;
    }
  
    return (
      <div className="space-y-4">
        <h2 className="text-xl font-bold">Liste des utilisateurs</h2>
        <div className="grid grid-cols-4 gap-4">
          {users
            .filter(u => u.id !== currentUser?.id)
            .map((u) => (
              <div 
                key={u.id} 
                className="flex flex-col items-center cursor-pointer"
                onClick={() => setSelectedUser(u)}
              >
                <img 
                  src={u.profilePicture || '/default-avatar.png'}
                  alt={`${u.username}'s avatar`}
                  className="w-16 h-16 rounded-full"
                />
                <span className="text-sm">{u.username}</span>
              </div>
            ))}
        </div>
      </div>
    );
  }, [users, currentUser, setSelectedUser]);

  const renderHomeTab = useMemo(() => (
    <div className="quicklink">
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Bienvenue, {currentUser?.firstName || currentUser?.username || 'Utilisateur'} !</h2>
        {currentUser && (
          <QuickLinks 
            links={quickLinks} 
            onAddLink={addQuickLink} 
            onDeleteLink={deleteQuickLink}
            user={currentUser} 
          />
        )}
        {renderUsersList}
      </div>
    </div>
  ), [currentUser, quickLinks, addQuickLink, deleteQuickLink, renderUsersList]);

  const renderUserProfileModal = useMemo(() => {
    if (!selectedUser) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <div className="flex flex-col items-center mb-4">
            <img 
              src={selectedUser.profilePicture || '/default-avatar.png'} 
              alt={`${selectedUser.firstName}'s avatar`} 
              className="w-24 h-24 rounded-full mb-2"
            />
            <h2 className="text-2xl font-bold">{selectedUser.username}</h2>
          </div>
          <p><strong>Nom complet:</strong> {selectedUser.firstName} {selectedUser.lastName}</p>
          <p><strong>Email:</strong> {selectedUser.email}</p>
          <p><strong>Téléphone:</strong> {selectedUser.phone || 'Non renseigné'}</p>
          <p><strong>Poste:</strong> {selectedUser.title || 'Non renseigné'}</p>
          <div className="flex justify-center mt-4">
            <Button onClick={() => setSelectedUser(null)} className="btn">Fermer</Button>
          </div>
        </div>
      </div>
    );
  }, [selectedUser]);

  const renderTasksTab = useMemo(() => (
    <TasksTab 
      currentUser={currentUser} 
      tasks={tasks} 
      setTasks={setTasks} 
      onNotificationChange={handleNotificationChange}
    />
  ), [currentUser, tasks, setTasks, handleNotificationChange]);

  const renderAdminTab = useMemo(() => (
    <div className="space-y-4">
      <UserManagement users={users} />
    </div>
  ), [users]);

  if (isLoading) {
    return <Preloader />;
  }

  if (!currentUser) {
    return <Login />;
  }
  
  return (
    <div className="bg-gray-100 min-h-screen p-4">
  <div className="max-w-4xl mx-auto space-y-4 pb-24">
    <div className="flex items-center space-x-4"> {/* Nouveau conteneur flex */}
      <img src={require('./images/logo.png')} alt="Logo" className="h-12" />
      <Card className="flex-grow">
        <CardContent className="p-4 flex items-center justify-between mt-[15px]">
          <div className="flex items-center flex-grow">
            <div className="relative flex-grow mr-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input type="text" placeholder="Rechercher" className="pl-10 py-2" />
            </div>
          </div>
          
          <div className="flex items-center">
            <h4 className="text-l text-red-500 mr-4">{currentUser?.firstName || currentUser?.username || 'Utilisateur'}</h4>
            <Button 
              onClick={handleLogout} 
              variant="outline"
              className="btn transition-colors hover:bg-blue-500 hover:text-white py-2"
            >
              <LogOut className="mr-2 h-4 w-4" /> Déconnexion
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
    
    <div>
          {currentUser?.role === 'admin' && activeTab === 'admin' && renderAdminTab}
      </div>
      
      <div style={{ display: activeTab === 'messages' ? 'block' : 'none' }}>
        <MessagesTab
          currentUser={currentUser}
          onNotificationChange={handleNotificationChange}
        />
      </div>
      {activeTab === 'home' && renderHomeTab}
      {activeTab === 'tasks' && renderTasksTab}
      {activeTab === 'flow' && renderFlowTab}
      {activeTab === 'visio' && (
          <div className="h-[calc(100vh-200px)]"> {/* Ajustez la hauteur selon vos besoins */}
            {renderVisioTab}
          </div>
          )}

      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg">
        <div className="flex justify-around max-w-4xl mx-auto">
          {[
            { icon: Home, label: 'Accueil', value: 'home' },
            { icon: MessageSquare, label: 'Messages', value: 'messages' },
            { icon: CheckSquare, label: 'Tâches', value: 'tasks' },
            { icon: Activity, label: 'Flow', value: 'flow' },
            { icon: Camera, label: 'Visio', value: 'visio' },
            ...(currentUser.role === 'admin' ? [{ icon: Users, label: 'Admin', value: 'admin' }] : []),
          ].map((item) => (
            <div 
              key={item.value} 
              className={`flex flex-col items-center cursor-pointer transition-colors hover:text-blue-500 ${activeTab === item.value ? 'text-blue-500' : 'text-gray-500'} relative`}
              onClick={() => setActiveTab(item.value)}
            >
              <item.icon className="h-6 w-6" />
              <span className="text-xs mt-1">{item.label}</span>
              {item.value === 'messages' && totalNotifications > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                  {totalNotifications}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
      {renderUserProfileModal}
      </div>
    </div>
  );
};



const ProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useUserContext();
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    setIsLoading(loading);  // Active le préchargeur si en cours de chargement
  }, [loading, setIsLoading]);

  if (isLoading) {
    return <Preloader />;  // Affiche le préchargeur tant que le statut de l'utilisateur est en cours de vérification
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
  
  const AppRoutes = () => {
    const { currentUser, loading } = useUserContext();
    const { isLoading, setIsLoading } = useLoading();
  
    useEffect(() => {
      setIsLoading(loading);
    }, [loading, setIsLoading]);
  
    if (isLoading) {
      return <Preloader />;  // Show preloader when loading
    }
  
    return (
      <Routes>
        <Route path="/login" element={currentUser ? <Navigate to="/" replace /> : <Login />} />
        <Route 
          path="/*" 
          element={
            <ProtectedRoute>
              <AppContent />
            </ProtectedRoute>
          } 
        />
      </Routes>
    );
  };
  
  const App = () => {
    useEffect(() => {
      requestNotificationPermission();
    }, []);
  
    const requestNotificationPermission = () => {
      if ("Notification" in window) {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log("Notifications autorisées");
          }
        });
      }
    };
  
    return (
      <Router>
        <UserProvider>
          <LoadingProvider>
            <NotificationProvider>
              <AppRoutes />
            </NotificationProvider>
          </LoadingProvider>
        </UserProvider>
      </Router>
    );
  };
  
  export default App;