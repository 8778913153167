import React, { createContext, useState, useContext, useCallback } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [totalNotifications, setTotalNotifications] = useState(0);

  const updateNotifications = useCallback((count) => {
    setTotalNotifications(count);
  }, []);

  return (
    <NotificationContext.Provider value={{ totalNotifications, updateNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};