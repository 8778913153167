import React from 'react';
import { Button } from './components/ui/button';
import { Video, Phone, Plus } from 'lucide-react';

const VisioTab = () => {
  const joinGoogleMeet = () => {
    const googleMeetUrl = 'https://meet.google.com/uqv-srew-zvm';
    window.open(googleMeetUrl, '_blank', 'noopener,noreferrer');
  };

  const join3CXMeeting = () => {
    const meetingUrl = 'https://1879.3cx.cloud/meet/herynyainamiadantsoa';
    window.open(meetingUrl, '_blank', 'noopener,noreferrer');
  };

  const createNewGoogleMeet = () => {
    const newGoogleMeetUrl = 'https://meet.google.com/new';
    window.open(newGoogleMeetUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex flex-col items-center justify-center h-full bg-gradient-to-br from-blue-100 to-purple-100 p-8 rounded-lg shadow-lg relative">
      <h2 className="text-3xl font-bold text-gray-800 mb-8">Choisissez votre plateforme de visioconférence</h2>
      
      <div className="space-y-6 w-full max-w-md">
        <Button
          onClick={joinGoogleMeet}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-4 px-6 rounded-full flex items-center justify-center text-lg transition-all duration-300 transform hover:scale-105 shadow-md"
        >
          <Video className="mr-3 h-6 w-6" />
          Démarrer une réunion Google Meet avec la Direction
        </Button>

        <Button
          onClick={join3CXMeeting}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-6 rounded-full flex items-center justify-center text-lg transition-all duration-300 transform hover:scale-105 shadow-md"
        >
          <Phone className="mr-3 h-6 w-6" />
          Démarrer une réunion 3CX avec la Direction
        </Button>
      </div>

      <p className="mt-8 text-center text-gray-600 max-w-md">
        Cliquez sur l'un des boutons ci-dessus pour démarrer ou rejoindre une réunion. 
        La visioconférence s'ouvrira dans un nouvel onglet de votre navigateur.
      </p>

      <Button
        onClick={createNewGoogleMeet}
        className="absolute bottom-4 right-4 bg-purple-500 hover:bg-purple-600 text-white rounded-full p-4 shadow-lg transition-all duration-300 transform hover:scale-110"
        title="Créer une nouvelle réunion Google Meet"
      >
        <Plus className="h-6 w-6" />
      </Button>
    </div>
  );
};

export default VisioTab;