const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.log("Ce navigateur ne supporte pas les notifications de bureau");
    return false;
  }

  const permission = await Notification.requestPermission();
  return permission === "granted";
};

const sendNotification = (title, options = {}) => {
  if (!("Notification" in window)) {
    console.log("Ce navigateur ne supporte pas les notifications de bureau");
    return;
  }

  // Si le titre est un objet, on suppose que c'est une erreur et on utilise les options comme titre
  if (typeof title === 'object' && title !== null) {
    options = { ...title, ...options };
    title = options.title || "Nouvelle notification";
  }

  // On s'assure que le body est une chaîne de caractères
  if (options && typeof options.body === 'object') {
    options.body = JSON.stringify(options.body);
  }

  if (Notification.permission === "granted") {
    new Notification(title, options);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        new Notification(title, options);
      }
    });
  }
};

const sendNotificationOnce = (key, title, options) => {
  const now = Date.now();
  const lastNotifications = JSON.parse(localStorage.getItem('lastNotifications') || '{}');
  const lastNotificationTime = lastNotifications[key];

  if (!lastNotificationTime || now - lastNotificationTime > 1000) { // 1 seconde de délai
    sendNotification(title, options);
    lastNotifications[key] = now;
    localStorage.setItem('lastNotifications', JSON.stringify(lastNotifications));
    console.log(`Notification sent: ${key}`, { title, ...options });
  } else {
    console.log(`Notification skipped (too recent): ${key}`);
  }
};

export { requestNotificationPermission, sendNotification, sendNotificationOnce };