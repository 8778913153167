import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

export const useGroupNotifications = (currentUser, groups, onNotificationChange) => {
  const [groupNotifications, setGroupNotifications] = useState({});

  useEffect(() => {
    if (!currentUser || groups.length === 0) return;

    const groupIds = groups.map(g => g.id);
    const q = query(
      collection(db, 'messages'),
      where('groupId', 'in', groupIds),
      where('senderId', '!=', currentUser.id),
      where('read', '==', false)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newNotifications = { ...groupNotifications };
      let totalNotifications = 0;

      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const message = change.doc.data();
          const groupId = message.groupId;
          newNotifications[groupId] = (newNotifications[groupId] || 0) + 1;
        }
      });

      for (const count of Object.values(newNotifications)) {
        totalNotifications += count;
      }

      setGroupNotifications(newNotifications);
      onNotificationChange(prevCount => prevCount + totalNotifications);
    });

    return () => unsubscribe();
  }, [currentUser, groups, onNotificationChange]);

  const resetGroupNotification = (groupId) => {
    setGroupNotifications(prev => {
      const updated = { ...prev, [groupId]: 0 };
      const totalNotifications = Object.values(updated).reduce((sum, count) => sum + count, 0);
      onNotificationChange(totalNotifications);
      return updated;
    });
  };

  return { groupNotifications, resetGroupNotification };
};