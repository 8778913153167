import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const OnlineStatus = ({ userId, showText = false }) => {
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const userStatusRef = doc(db, 'status', userId);
    const unsubscribe = onSnapshot(userStatusRef, (doc) => {
      if (doc.exists()) {
        setIsOnline(doc.data().online);
      } else {
        setIsOnline(false);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  if (showText) {
    return (
      <p className="text-xs text-gray-500">
        {isOnline ? 'En ligne' : 'Hors ligne'}
      </p>
    );
  }

  return (
    <span className={`absolute bottom-0 right-0 w-3 h-3 rounded-full ${isOnline ? 'bg-green-500' : 'bg-gray-400'}`}></span>
  );
};

export default OnlineStatus;