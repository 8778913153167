import React, { useState, useEffect } from 'react';
import { PlusCircle, X, Search } from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { db } from '../firebase'; // Assurez-vous que ce chemin est correct
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, } from 'firebase/firestore';

const QuickLinks = ({ user }) => {
  const [links, setLinks] = useState([]);
  const [showNewLinkForm, setShowNewLinkForm] = useState(false);
  const [newLink, setNewLink] = useState({ title: '', url: '' });
  const [searchTerm, setSearchTerm] = useState('');

  const filteredLinks = links.filter(link => 
    link.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    link.url.toLowerCase().includes(searchTerm.toLowerCase())
  );



  useEffect(() => {
    if (user) {
      fetchLinks();
    }
  }, [user]);

  const fetchLinks = async () => {
    try {
      const q = query(collection(db, 'quicklinks'), where('userId', '==', user.id));
      const querySnapshot = await getDocs(q);
      const fetchedLinks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLinks(fetchedLinks);
    } catch (error) {
      console.error("Erreur lors de la récupération des liens rapides:", error);
    }
  };

  const addLink = async () => {
    if (newLink.title.trim() !== '' && newLink.url.trim() !== '') {
      try {
        const docRef = await addDoc(collection(db, 'quicklinks'), {
          ...newLink,
          userId: user.id,
          createdAt: new Date()
        });
        setLinks([...links, { id: docRef.id, ...newLink }]);
        setNewLink({ title: '', url: '' });
        setShowNewLinkForm(false);
      } catch (error) {
        console.error("Erreur lors de l'ajout du lien rapide:", error);
      }
    }
  };

  const deleteLink = async (linkId) => {
    try {
      await deleteDoc(doc(db, 'quicklinks', linkId));
      setLinks(links.filter(link => link.id !== linkId));
    } catch (error) {
      console.error("Erreur lors de la suppression du lien rapide:", error);
    }
  };

  return (
    <Card>
      
      <CardContent className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Liens Rapides</h2>
          <Button onClick={() => setShowNewLinkForm(!showNewLinkForm)} variant="outline">
            {showNewLinkForm ? (
              <>
                <X className="h-4 w-4 mr-2" /> Fermer
              </>
            ) : (
              <>
                <PlusCircle className="h-4 w-4 mr-2" /> Ajouter un lien
              </>
            )}
          </Button>
        </div>

        {!showNewLinkForm && (
          <div className="relative mb-4">
            <Input
              placeholder="Rechercher un lien..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
            />
            <Search className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
        )}

        {showNewLinkForm && (
          <div className="mb-4 space-y-2">
            <Input
              placeholder="Titre du lien"
              value={newLink.title}
              onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
            />
            <Input
              placeholder="URL"
              value={newLink.url}
              onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
            />
            <Button onClick={addLink} className="w-full">Ajouter</Button>
          </div>
        )}

<ul className="space-y-2">
          {filteredLinks.map((link) => (
            <li key={link.id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
              <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                {link.title}
              </a>
              <Button onClick={() => deleteLink(link.id)} variant="ghost" size="sm">
                <X className="h-4 w-4" />
              </Button>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default QuickLinks;