import React, { createContext, useState, useEffect, useContext } from 'react';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, onSnapshot, collection } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateOnlineStatus = async (userId, isOnline) => {
    if (userId) {
      await setDoc(doc(db, 'status', userId), { online: isOnline }, { merge: true });
    }
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userDocRef);
        if (userSnap.exists()) {
          const userData = { id: user.uid, ...userSnap.data() };
          setCurrentUser(userData);
          await updateOnlineStatus(user.uid, true); // Mettre à jour le statut à la connexion
        }
      } else {
        if (currentUser) {
          await updateOnlineStatus(currentUser.id, false); // Mettre à jour le statut à la déconnexion
        }
        setCurrentUser(null);
      }
      setLoading(false);
    });

    const unsubscribeUsers = onSnapshot(collection(db, 'users'), (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeUsers();
    };
  }, []);

  const updateCurrentUser = async (user) => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const snapshot = await getDoc(userDocRef);
      const userData = { id: user.uid, ...snapshot.data() };
      setCurrentUser(userData);
      await updateOnlineStatus(user.uid, true); // Mettre à jour le statut lors de la mise à jour de l'utilisateur
    } else {
      if (currentUser) {
        await updateOnlineStatus(currentUser.id, false);
      }
      setCurrentUser(null);
    }
  };

  return (
    <UserContext.Provider value={{ 
      currentUser, 
      updateCurrentUser,
      users: users.filter(user => user.id !== currentUser?.id),
      loading 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};