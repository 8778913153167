import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent } from './ui/card.js';
import { Input } from './ui/input.js';
import { Button } from './ui/button.js';
import { db, storage } from '../firebase.js';
import { collection, query, where, getDocs, addDoc, onSnapshot, limit, orderBy, updateDoc, doc, writeBatch, deleteDoc } from 'firebase/firestore';
import { useUserContext } from '../contexts/UserContext.js';
import { Loader, Trash2, Paperclip } from 'lucide-react';
import { Users, Send } from 'lucide-react';
import OnlineStatus from './OnlineStatus.js';
import { sendNotificationOnce } from '../utils/notificationService.js';
import { markMessageAsSent, markMessageAsRead } from '../utils/messageUtils.js';
import { useGroupNotifications } from './groupNotifications.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { File } from 'lucide-react';

const MessagesTab = ({ currentUser, onNotificationChange }) => {
  const [collaborators, setCollaborators] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [messages, setMessages] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [groups, setGroups] = useState([]);
  const [showNewGroupForm, setShowNewGroupForm] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { onlineUsers } = useUserContext();
  const messagesEndRef = useRef(null);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [notifications, setNotifications] = useState({});
  const [attachment, setAttachment] = useState(null);

  const getFileType = (url) => {
    if (!url) return 'unknown';
    const extension = url.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    return imageExtensions.includes(extension) ? 'image' : 'file';
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  const renderAttachment = (attachment) => {
    if (!attachment) return null;
  
    switch (attachment.type) {
      case 'image':
        return (
          <a 
            href={attachment.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block mt-2"
          >
            <img 
              src={attachment.url} 
              alt={attachment.name || "Image jointe"}
              className="max-w-full h-auto rounded cursor-pointer"
              style={{ maxHeight: '200px' }} 
            />
          </a>
        );
      default:
        return (
          <a 
            href={attachment.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center text-blue-300 underline mt-2"
          >
            <File className="mr-1" size={16} />
            {attachment.name || "Pièce jointe"}
          </a>
        );
    }
  };


  const { groupNotifications, resetGroupNotification } = useGroupNotifications(currentUser, groups, onNotificationChange);

  const updateNotifications = useCallback((newNotifications) => {
    setNotifications(prevNotifications => {
      const updatedNotifications = { ...prevNotifications, ...newNotifications };
      const totalUnread = Object.values(updatedNotifications).reduce((sum, count) => sum + count, 0);
      onNotificationChange(totalUnread);
      return updatedNotifications;
    });
  }, [onNotificationChange]);

  const resetNotifications = useCallback(async (userId) => {
    if (userId) {
      const batch = writeBatch(db);
      const messagesRef = collection(db, 'messages');
      const q = query(
        messagesRef,
        where('senderId', '==', userId),
        where('receiverId', '==', currentUser.id),
        where('read', '==', false)
      );
      
      const snapshot = await getDocs(q);
      snapshot.docs.forEach((doc) => {
        batch.update(doc.ref, { read: true });
        markMessageAsRead(doc.id);
      });
      
      await batch.commit();

      setNotifications(prevNotifications => {
        const updatedNotifications = { ...prevNotifications, [userId]: 0 };
        const totalUnread = Object.values(updatedNotifications).reduce((sum, count) => sum + count, 0);
        onNotificationChange(totalUnread);
        return updatedNotifications;
      });
    }
  }, [currentUser.id, onNotificationChange]);

  const handleSelectUser = useCallback((collaborator) => {
    setSelectedUser(collaborator);
    setSelectedGroup(null);
    resetNotifications(collaborator.id);

    const participantIds = [currentUser.id, collaborator.id].sort();
    const conversationId = participantIds.join('_');

    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('conversationId', '==', conversationId),
      orderBy('timestamp', 'asc')
    );

    return onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(prevMessages => ({
        ...prevMessages,
        [collaborator.id]: newMessages
      }));
      scrollToBottom();
    });
  }, [currentUser.id, resetNotifications]);

  const handleSelectGroup = useCallback((group) => {
    setSelectedGroup(group);
    setSelectedUser(null);
    resetGroupNotification(group.id);
    
    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('groupId', '==', group.id),
      orderBy('timestamp', 'asc')
    );

    return onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(prevMessages => ({
        ...prevMessages,
        [group.id]: newMessages
      }));
      scrollToBottom();
    });
  }, []);

  useEffect(() => {
    const unreadMessagesRef = collection(db, 'messages');
    const q = query(
      unreadMessagesRef,
      where('receiverId', '==', currentUser.id),
      where('read', '==', false)
    );
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newNotifications = {};
      let hasChanges = false;
  
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const message = change.doc.data();
          const senderId = message.senderId;
          newNotifications[senderId] = (newNotifications[senderId] || 0) + 1;
          hasChanges = true;

          const sender = collaborators.find(c => c.id === senderId);
          const senderName = sender ? sender.firstName : 'Utilisateur inconnu';

          sendNotificationOnce(
            `message-${change.doc.id}`,
            "Nouveau message",
            { 
              body: `${senderName}\n${message.content}`
            }
          );
        }
      });
  
      if (hasChanges) {
        updateNotifications(newNotifications);
      }
    });
  
    return () => unsubscribe();
  }, [currentUser.id, updateNotifications, collaborators]);

  useEffect(() => {
    const fetchCollaborators = async () => {
      setLoadingMore(true);
      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, limit(20 * page));

      try {
        const snapshot = await getDocs(q);
        const allUsers = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.id !== currentUser.id);

        setCollaborators(allUsers);
      } catch (error) {
        console.error("Erreur lors de la récupération des collaborateurs:", error);
      } finally {
        setIsLoading(false);
        setLoadingMore(false);
      }
    };

    fetchCollaborators();
  }, [currentUser.id, page]);

  useEffect(() => {
    const fetchGroups = () => {
      const groupsQuery = query(
        collection(db, 'groups'),
        where('members', 'array-contains', currentUser.id)
      );
      return onSnapshot(groupsQuery, (snapshot) => {
        const fetchedGroups = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGroups(fetchedGroups);
      });
    };

    const unsubscribe = fetchGroups();
    return () => unsubscribe();
  }, [currentUser.id]);

  useEffect(() => {
    let unsubscribe;
    if (selectedUser) {
      unsubscribe = handleSelectUser(selectedUser);
    } else if (selectedGroup) {
      unsubscribe = handleSelectGroup(selectedGroup);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedUser, selectedGroup, handleSelectUser, handleSelectGroup]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const createGroup = async () => {
    if (!newGroupName.trim() || selectedMembers.length === 0) return;
    try {
      await addDoc(collection(db, 'groups'), {
        name: newGroupName,
        members: [...selectedMembers, currentUser.id],
        createdBy: currentUser.id,
        createdAt: new Date(),
      });
      setShowNewGroupForm(false);
      setNewGroupName('');
      setSelectedMembers([]);
    } catch (error) {
      console.error('Erreur lors de la création du groupe:', error);
    }
  };

  const deleteGroup = async (groupId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce groupe ?")) {
      try {
        await deleteDoc(doc(db, 'groups', groupId));
        setSelectedGroup(null);
        setMessages([]);
      } catch (error) {
        console.error('Erreur lors de la suppression du groupe:', error);
      }
    }
  };

  const handleNewMessage = async () => {
    if (newMessage.trim() === '' && !attachment) return;
    
    let attachmentData = null;
    if (attachment) {
      try {
        const storageRef = ref(storage, `attachments/${Date.now()}_${attachment.name}`);
        await uploadBytes(storageRef, attachment);
        const attachmentUrl = await getDownloadURL(storageRef);
        attachmentData = {
          url: attachmentUrl,
          type: attachment.type.split('/')[0], // 'image', 'video', 'audio', etc.
          name: attachment.name
        };
        console.log("Pièce jointe uploadée:", attachmentData);
      } catch (error) {
        console.error("Erreur lors de l'upload de la pièce jointe:", error);
      }
    }

    const messageData = {
      senderId: currentUser.id,
      content: newMessage,
      timestamp: new Date(),
      read: false,
      status: 'envoyé',
      attachment: attachmentData
    };
  
    if (selectedUser) {
      messageData.receiverId = selectedUser.id;
      messageData.conversationId = [currentUser.id, selectedUser.id].sort().join('_');
    } else if (selectedGroup) {
      messageData.groupId = selectedGroup.id;
    } else {
      return;
    }
  
    try {
      await addDoc(collection(db, 'messages'), messageData);
      setNewMessage('');
      setAttachment(null);
      scrollToBottom();
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
    }
  };

  const handleAttachment = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
    }
  };
  
  const handleMessageRead = useCallback(async (messageId) => {
    await updateDoc(doc(db, 'messages', messageId), { status: 'lu', read: true });
  }, []);
  
  useEffect(() => {
    if (selectedUser) {
      const unreadMessages = messages[selectedUser.id]?.filter(
        m => m.receiverId === currentUser.id && !m.read
      );
      unreadMessages?.forEach(message => handleMessageRead(message.id));
    }
  }, [selectedUser, messages, currentUser.id, handleMessageRead]);

  return (
    <div className="flex h-[calc(100vh-200px)]">
      <div className="w-1/4 overflow-y-auto border-r">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader className="animate-spin" />
          </div>
        ) : (
          <>
            <Button onClick={() => setShowNewGroupForm(true)} className="w-full mb-2">
              <Users className="mr-2" /> Créer un groupe
            </Button>
            {showNewGroupForm && (
              <Card className="mb-2">
                <CardContent>
                  <Input
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    placeholder="Nom du groupe"
                    className="mb-2"
                  />
                  {collaborators.map((collab, index) => (
                    <div key={collab.id || index} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        id={collab.id}
                        checked={selectedMembers.includes(collab.id)}
                        onChange={() => {
                          if (selectedMembers.includes(collab.id)) {
                            setSelectedMembers(selectedMembers.filter((id) => id !== collab.id));
                          } else {
                            setSelectedMembers([...selectedMembers, collab.id]);
                          }
                        }}
                        className="mr-2"
                      />
                      <label htmlFor={collab.id}>{collab.firstName || 'Collaborateur'}</label>
                    </div>
                  ))}
                  <Button onClick={createGroup} className="mt-2">
                    Créer
                  </Button>
                </CardContent>
              </Card>
            )}
  
            {groups.map((group) => (
              <div
                key={group.id}
                className={`flex items-center p-3 cursor-pointer hover:bg-gray-100 ${
                  selectedGroup?.id === group.id ? 'bg-blue-100' : ''
                } relative`}
                onClick={() => handleSelectGroup(group)}
              >
                <div className="flex-grow">
                  <div className="w-10 h-10 rounded-full mr-3 bg-gray-300 flex items-center justify-center text-xl font-semibold text-gray-600">
                    {group.name.charAt(0).toUpperCase()}
                  </div>
                  <div className="flex-grow">
                    <h3 className="font-semibold">{group.name}</h3>
                    <p className="text-sm text-gray-500">{group.members.length} membres</p>
                  </div>
                </div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteGroup(group.id);
                  }}
                  variant="ghost"
                  size="sm"
                  className="text-red-500 hover:bg-red-100"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
                {groupNotifications[group.id] > 0 && (
                  <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {groupNotifications[group.id]}
                  </div>
                )}
              </div>
            ))}
  
            {collaborators.map((collaborator) => (
              <div
                key={collaborator.id}
                className={`flex items-center p-3 cursor-pointer hover:bg-gray-100 ${
                  selectedUser?.id === collaborator.id ? 'bg-blue-100' : ''
                } relative`}
                onClick={() => handleSelectUser(collaborator)}
              >
                <div className="relative">
                  {collaborator.profilePicture ? (
                    <img
                      src={collaborator.profilePicture}
                      alt={collaborator.firstName || 'Collaborateur'}
                      className="w-10 h-10 rounded-full mr-3"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full mr-3 bg-gray-300 flex items-center justify-center text-xl font-semibold text-gray-600">
                      {collaborator.firstName ? collaborator.firstName.charAt(0).toUpperCase() : '?'}
                    </div>
                  )}
                  <OnlineStatus userId={collaborator.id} />
                </div>
                <div className="flex-grow">
                  <h3 className="font-semibold">{collaborator.firstName || 'Collaborateur'}</h3>
                  <OnlineStatus userId={collaborator.id} showText={true} />
                </div>
                {notifications[collaborator.id] > 0 && (
                  <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {notifications[collaborator.id]}
                  </div>
                )}
              </div>
            ))}
    
            {loadingMore && (
              <div className="flex justify-center p-2">
                <Loader className="animate-spin" />
              </div>
            )}
            <Button onClick={() => setPage((prevPage) => prevPage + 1)} className="w-full mt-2">
              Charger plus d'utilisateurs
            </Button>
          </>
        )}
      </div>
  
      <div className="flex-1 flex flex-col">
        {(selectedUser || selectedGroup) ? (
          <>
            <div className="bg-gray-100 p-3 border-b">
              <h2 className="font-bold">
                {selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : selectedGroup.name}
              </h2>
            </div>
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {(() => {
                const messageList = selectedUser ? messages[selectedUser.id] : messages[selectedGroup.id];
                const lastMessageFromCurrentUser = messageList?.findLast(msg => msg.senderId === currentUser.id);
  
                return messageList?.map((message, index) => (
                  <div key={message.id || index} className="flex flex-col">
                    <div className={`flex ${message.senderId === currentUser.id ? 'justify-end' : 'justify-start'}`}>
                      {selectedGroup && message.senderId !== currentUser.id && (
                        <div className="flex flex-col items-center mr-2">
                          <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-sm font-semibold text-gray-600 mb-1">
                            {collaborators.find(c => c.id === message.senderId)?.firstName?.charAt(0).toUpperCase() || '?'}
                          </div>
                          <span className="text-xs text-gray-500">
                            {collaborators.find(c => c.id === message.senderId)?.firstName || 'Utilisateur'}
                          </span>
                        </div>
                      )}
                      <div className={`max-w-xs p-3 rounded-lg ${message.senderId === currentUser.id ? 'bg-blue-500 text-white' : 'bg-gray-200'} relative group`}>
          <div>{message.content}</div>
          {message.attachment && renderAttachment(message.attachment)}
          {message.senderId !== currentUser.id && (
            <span className="text-xs text-gray-500 absolute bottom-0 right-0 mr-1 mb-1">
              {formatTime(message.timestamp.toDate())}
            </span>
          )}
          {message.senderId !== currentUser.id && (
            <div className="invisible group-hover:visible absolute bottom-full left-0 bg-gray-800 text-white text-xs rounded p-1 mb-1">
              {new Date(message.timestamp.toDate()).toLocaleString()}
            </div>
          )}
        </div>
      </div>
      <div className="text-xs text-gray-500 mt-1 text-right">
        {message.senderId === currentUser.id ? `Envoyé le ${new Date(message.timestamp.toDate()).toLocaleDateString()} à ${new Date(message.timestamp.toDate()).toLocaleTimeString()}` : ''}
      </div>
    </div>
        ));
      })()}
      <div ref={messagesEndRef} />
            </div>
            <div className="p-4 border-t">
              <div className="flex space-x-2">
                <textarea
                  placeholder="Tapez votre message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onFocus={() => {
                    if (selectedUser) {
                      const unreadMessages = messages[selectedUser.id]?.filter(
                        m => m.receiverId === currentUser.id && !m.read
                      );
                      unreadMessages?.forEach(message => handleMessageRead(message.id));
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleNewMessage();
                    }
                  }}
                  className="flex-grow border rounded p-2"
                  rows={2}
                />
                <input
          type="file"
          onChange={handleAttachment}
          style={{ display: 'none' }}
          id="attachment"
        />
        <label htmlFor="attachment" className="cursor-pointer">
          <Paperclip className="h-6 w-6 text-gray-500" />
        </label>


                <Button onClick={handleNewMessage}>
                  <Send className="h-4 w-4" />
                </Button>
              </div>

              {attachment && (
        <div className="mt-2 text-sm text-gray-600">
          Pièce jointe : {attachment.name}
        </div>
      )}

            </div>
          </>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Sélectionnez un collaborateur ou un groupe pour commencer à discuter!
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesTab;