import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Select } from '../components/ui/select';
import { Textarea } from '../components/ui/Textarea2';
import Checkbox2 from '../components/ui/Checkbox2';
import { X, Edit, Search, PlusCircle } from 'lucide-react';
import { db } from '../firebase';
import { collection, query, onSnapshot, addDoc, updateDoc, doc, arrayUnion, deleteDoc } from 'firebase/firestore';
import { useUserContext } from '../contexts/UserContext';

import { sendNotification, sendNotificationOnce } from '../utils/notificationService';


const FlowTab = () => {
  const [cards, setCards] = useState([]);
  const [newCard, setNewCard] = useState({ name: '', description: '', status: 'en attente', assignedUsers: [] });
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [comment, setComment] = useState('');
  const [commentPopup, setCommentPopup] = useState(null);
  const { currentUser, users } = useUserContext();
  const isInitialMount = useRef(true);
  const isInitialLoad = useRef(true);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCards = cards.filter(card =>
    card.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    card.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const userConnectionTime = new Date();
    
    const q = query(collection(db, 'flowCards'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedCards = querySnapshot.docs.map(doc => {
        const cardData = doc.data();
        return { id: doc.id, ...cardData };
      });

      setCards(updatedCards);

      querySnapshot.docChanges().forEach((change) => {
        const cardData = change.doc.data();
        const cardCreationTime = cardData.createdAt.toDate();
    
        if (change.type === 'added' && cardCreationTime > userConnectionTime) {
          if (cardData.createdBy !== currentUser.id) {
            sendNotificationOnce(
              "Nouvelle carte créée",
              { body: `Une nouvelle carte "${cardData.name}" a été créée` }
            );
          }
        }
  
        if (change.type === 'modified') {
          const oldCard = updatedCards.find(c => c.id === change.doc.id);
          if (oldCard && cardData.comments && oldCard.comments) {
            const newComments = cardData.comments.slice(oldCard.comments.length);
            newComments.forEach((comment) => {
              if (comment.userId !== currentUser.id && cardCreationTime > userConnectionTime) {
                sendNotificationOnce(
                  "Nouveau commentaire",
                  { body: `Un commentaire a été ajouté à la carte "${cardData.name}"` }
                );
              }
            });
          }
        }
      });
    });
  
    return () => unsubscribe();
  }, [currentUser.id]);


  const handleCreateCard = async () => {
    if (newCard.name.trim() === '' || newCard.description.trim() === '' || newCard.assignedUsers.length === 0) {
      alert("Veuillez remplir tous les champs et sélectionner au moins un utilisateur.");
      return;
    }
    await addDoc(collection(db, 'flowCards'), {
      ...newCard,
      createdBy: currentUser.id,
      createdAt: new Date(),
      comments: [],
      completed: false
    });
    setNewCard({ name: '', description: '', status: 'en attente', assignedUsers: [] });
    setShowNewCardForm(false);
    
    // Envoyer une notification
    sendNotificationOnce("Nouvelle carte créée", { body: `La carte "${newCard.name}" a été créée.` });
  };

  const handleDeleteCard = async (cardId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette carte ?")) {
      await deleteDoc(doc(db, 'flowCards', cardId));
    }
  };

  const handleUpdateCard = async (cardId, updates) => {
    await updateDoc(doc(db, 'flowCards', cardId), updates);
    setEditingCard(null);
  };

  const handleAddComment = async (cardId) => {
    if (comment.trim() === '') return;
    const newComment = {
      text: comment,
      userId: currentUser.id,
      createdAt: new Date()
    };
    await updateDoc(doc(db, 'flowCards', cardId), {
      comments: arrayUnion(newComment)
    });
    setComment('');

    // Envoyer une notification
    const card = cards.find(c => c.id === cardId);
    sendNotificationOnce("Nouveau commentaire", { body: `Nouveau commentaire sur la carte "${card.name}"` });
  };

  const handleToggleCardCompletion = async (cardId, completed) => {
    await updateDoc(doc(db, 'flowCards', cardId), { completed });
    setCommentPopup(null);
  };

  const handleUserAssignment = (userId) => {
    setNewCard(prev => {
      const updatedUsers = prev.assignedUsers.includes(userId)
        ? prev.assignedUsers.filter(id => id !== userId)
        : [...prev.assignedUsers, userId];
      return { ...prev, assignedUsers: updatedUsers };
    });
  };

  const canEditAllFields = (card) => card.createdBy === currentUser.id;
  const canEditStatus = (card) => card.assignedUsers.includes(currentUser.id) || card.createdBy === currentUser.id;
  const canAccessComments = (card) => card.assignedUsers.includes(currentUser.id) || card.createdBy === currentUser.id;

  return (
    <div className="space-y-4">
      <Button 
        onClick={() => setShowNewCardForm(!showNewCardForm)}
        className="w-full justify-center"
      >
        {showNewCardForm ? (
          <>
            <X className="mr-2 h-4 w-4" /> Fermer
          </>
        ) : (
          <>
            <PlusCircle className="mr-2 h-4 w-4" /> Créer une nouvelle carte
          </>
        )}
      </Button>

      {!showNewCardForm && (
        <div className="relative">
          <Input
            placeholder="Rechercher une carte..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10"
          />
          <Search className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
        </div>
      )}
        
      {showNewCardForm && (
        
        <Card>
          <CardContent className="space-y-2">
          <h2 className="text-center font-bold">NOUVELLE CARTE</h2>

            <Input
              placeholder="Nom de la mission"
              value={newCard.name}
              onChange={(e) => setNewCard({ ...newCard, name: e.target.value })}
            />
            <Textarea
              placeholder="Description de la mission"
              value={newCard.description}
              onChange={(e) => setNewCard({ ...newCard, description: e.target.value })}
              rows={4}
            />
            <Select
              value={newCard.status}
              onChange={(e) => setNewCard({ ...newCard, status: e.target.value })}
            >
              <option value="en attente">En attente</option>
              <option value="en cours">En cours</option>
              <option value="validé">Validé</option>
              <option value="annulé">Annulé</option>
            </Select>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Assigner des utilisateurs</label>
              <div className="space-y-2 max-h-48 overflow-y-auto">
                {users.map(user => (
                  <div key={user.id} className="flex items-center">
                    <Checkbox2
                      id={`user-${user.id}`}
                      checked={newCard.assignedUsers.includes(user.id)}
                      onChange={() => handleUserAssignment(user.id)}
                    />
                    <label htmlFor={`user-${user.id}`} className="ml-2 text-sm text-gray-700">
                      {user.firstName} {user.lastName}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <Button onClick={handleCreateCard}>Créer la carte</Button>
          </CardContent>
        </Card>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredCards.map(card => (
          <Card 
            key={card.id} 
            className={`${card.completed ? 'bg-red-100' : ''} ${
              canAccessComments(card) ? 'cursor-pointer' : 'opacity-50'
            } relative transition-all duration-300 ease-in-out hover:shadow-md`}
            onClick={() => {
              if (canAccessComments(card) && (!card.completed || card.createdBy === currentUser.id)) {
                setCommentPopup(card.id);
              }
            }}
          >
            <CardContent className={`space-y-2 ${card.completed ? 'line-through' : ''}`}>
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-bold">{card.name}</h3>
                <div className="flex items-center">
                  {canEditAllFields(card) && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingCard(editingCard === card.id ? null : card.id);
                      }}
                      variant="ghost"
                      size="sm"
                      className="text-blue-500 hover:bg-blue-100 mr-2"
                    >
                      <Edit size={16} />
                    </Button>
                  )}
                  {card.createdBy === currentUser.id && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCard(card.id);
                      }}
                      variant="ghost"
                      size="sm"
                      className="text-red-500 hover:bg-red-100"
                    >
                      <X size={16} />
                    </Button>
                  )}
                </div>
              </div>
              {editingCard === card.id ? (
                <div onClick={(e) => e.stopPropagation()} className="space-y-2">
                  {canEditAllFields(card) && (
                    <>
                      <Input
                        value={card.name}
                        onChange={(e) => setCards(cards.map(c => c.id === card.id ? { ...c, name: e.target.value } : c))}
                      />
                      <Textarea
                        value={card.description}
                        onChange={(e) => setCards(cards.map(c => c.id === card.id ? { ...c, description: e.target.value } : c))}
                        rows={4}
                      />
                    </>
                  )}
                  {canEditStatus(card) && (
                    <Select
                      value={card.status}
                      onChange={(e) => setCards(cards.map(c => c.id === card.id ? { ...c, status: e.target.value } : c))}
                    >
                      <option value="en attente">En attente</option>
                      <option value="en cours">En cours</option>
                      <option value="validé">Validé</option>
                      <option value="annulé">Annulé</option>
                    </Select>
                  )}
                  {canEditAllFields(card) && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Assigner des utilisateurs</label>
                      <div className="space-y-2 max-h-48 overflow-y-auto">
                        {users.map(user => (
                          <div key={user.id} className="flex items-center">
                            <Checkbox2
                              id={`user-${user.id}`}
                              checked={card.assignedUsers.includes(user.id)}
                              onChange={() => {
                                const updatedUsers = card.assignedUsers.includes(user.id)
                                  ? card.assignedUsers.filter(id => id !== user.id)
                                  : [...card.assignedUsers, user.id];
                                setCards(cards.map(c => c.id === card.id ? { ...c, assignedUsers: updatedUsers } : c));
                              }}
                            />
                            <label htmlFor={`user-${user.id}`} className="ml-2 text-sm text-gray-700">
                              {user.firstName} {user.lastName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <Button onClick={() => handleUpdateCard(card.id, card)}>
                    Sauvegarder
                  </Button>
                </div>
              ) : (
                <>
                  <p>{card.description}</p>
                  <p>État: {card.status}</p>
                  <p>Assigné à: {card.assignedUsers.map(userId => 
                    users.find(u => u.id === userId)?.firstName
                  ).join(', ')}</p>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </div>

      {commentPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full max-h-[80vh] overflow-y-auto">
            {(() => {
              const card = cards.find(c => c.id === commentPopup);
              return (
                <>
                  <h2 className="text-2xl font-bold mb-2">{card.name}</h2>
                  <p className="mb-2">{card.description}</p>
                  <p className="mb-4">État: {card.status}</p>
                  <hr className="mb-4" />
                </>
              );
            })()}
            <h3 className="text-xl font-bold mb-4">Commentaires</h3>
            <div className="space-y-4 mb-4">
              {cards.find(c => c.id === commentPopup).comments.map((comment, index) => (
                <div key={index} className={`p-3 rounded ${comment.userId === currentUser.id ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}>
                  <p>{comment.text}</p>
                  <small className={`font-semibold ${comment.userId === currentUser.id ? 'text-white' : 'text-blue-500'}`}>
                    {comment.userId === currentUser.id ? 'Moi' : users.find(u => u.id === comment.userId)?.firstName} - {new Date(comment.createdAt.toDate()).toLocaleString()}
                  </small>
                </div>
              ))}
            </div>
            <Textarea
              placeholder="Ajouter un commentaire"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={3}
              className="mb-2"
            />
            <div className="flex justify-between items-center">
              <Button onClick={() => handleAddComment(commentPopup)}>Commenter</Button>
              <div className="flex items-center">
                <Checkbox2
                  id="complete-card"
                  checked={cards.find(c => c.id === commentPopup).completed}
                  onChange={(e) => handleToggleCardCompletion(commentPopup, e.target.checked)}
                  disabled={cards.find(c => c.id === commentPopup).createdBy !== currentUser.id}
                />
                <label htmlFor="complete-card" className="ml-2">Terminer</label>
              </div>
              <Button onClick={() => setCommentPopup(null)} variant="outline">Fermer</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowTab;