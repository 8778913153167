import React from 'react';
import { useLoading } from '../contexts/LoadingContext'; // Assurez-vous que ce chemin est correct
import loaderImage from '../images/loader.png';

const Preloader = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-50 z-50">
      <img 
        src={loaderImage}  
        alt="Chargement" 
        className="w-50 h-32 animate-spin-center"
      />
    </div>
  );
};

export default Preloader;